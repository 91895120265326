import HeroContainer from '../components/HeroContainer'
import FormContact from '../components/FormContact'
import { CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";

export default function Contacto(){
    return(
        
        <>
       
        <HeroContainer img="images/margarita-up.jpg" text1="Contactate con" text2="La Margarita" />
        <div className=''>
        <section>
    <div className="container mx-auto flex justify-center items-center  pt-12 h-full ">
        <div className="grid lg:grid-cols-2 lg:m-20  rounded rounded-xl bg-gray-700/10 ">
          
        <div className="bg-yellow-600 md:px-9 pt-14 ">
      <h1 className="text-3xl text-white font-medium">Medios de contacto:</h1>
      <p className="py-2 text-base text-gray-100"></p> 
      <div className="location mt-7">
       <div className="flex my-4 items-center">
        <span className=""><i className="fa-solid fa-location-dot text-gray-200"><CiLocationOn size={56} /></i></span>
        <div className="text-gray-100 font-bebas text-2xl">Ubicación: Olavarria S/N, Barrio Mi Rincon, B1748 General Rodriguez, Pcia de Buenos Aires</div>
      </div>
      <div className="flex my-4 items-center">
        <span className=""><i className="fa-solid fa-phone text-gray-200"><BsTelephone size={48}/></i></span>
        <span className="text-gray-100 font-bebas text-2xl">TELEFONO:  +54 9 1155822610</span>
        
      </div>
      <div className="flex my-4 items-center">
        <span className=""><i className="fa-solid fa-envelope text-gray-200"><CiMail size={48} /></i></span>
        <span className="text-gray-100 font-bebas text-2xl">MAIL: info@estanciamargarita.com.ar</span>
       
      </div>
      <span className="text-gray-100 font-bebas text-2xl">Comercializa Pedraza Viajes y Turismo SA</span>
      </div>
      
    </div>
    <FormContact/>
    
    </div>
    
        </div>

</section>



    </div>
        </>
    )
}